<template>
  <div id="app">
   <div>you are logged in</div>
   <table class='table' width='100%'>
     <tr>
       <td style='width:180pt;' >Tenant</td><td>{{ tenant }}/{{ tenantID }}</td>
     </tr> 
     <tr>
       <td>User</td><td>{{ login }}</td>
     </tr>  

      <tr>
       <td>Token</td><td>{{ token }}</td>
     </tr>  
     <tr>
       <td>X-Token</td><td>{{ xtoken }}</td>
     </tr>  
     <tr>
       <td>Hash</td><td>{{ token3 }}</td>
     </tr>  
   </table>
   <button class='button' @click="showAccessPoint">Access Point settings</button>
   <button class='button' @click="showTenant">Tenant settings</button>
   <button class='button' @click="reload">Refresh</button>
   <button class='button' @click="doLogout">LOGOUT</button>
   
  </div>
</template>

<script>
import { myTimer, myName } from '@/variables'


export default {
  name: 'app',
  data () {
    return {
      title: myName,
      myTimer,
      tenant: sessionStorage.tenant,
      xtoken: sessionStorage.userToken,
      token: sessionStorage.token,
      token3: sessionStorage.Xtoken,
      tenantID: sessionStorage.tenantId,
      login: sessionStorage.login
    }
  },
   methods: {
      doLogout() {
        this.$router.replace("/logout");
      },
      showTenant() {
        this.$router.replace("/showTenant");
        //this.$router.showTenant;
        },
      showAccessPoint() {
        this.$router.replace("/showAccessPoint/"+sessionStorage.accessPointId);
        //this.$router.showTenant;
        },
      showUsers() {
        this.$router.replace("/users");
        //this.$router.showTenant;
      },
      reload() {
      	this.$router.go();
      },
      timedRefresh(timeoutPeriod) {
	    setTimeout("reload()",timeoutPeriod);
	  }
   },
		
   created() {
      window.onload = this.timedRefresh(2000);
   }
}
</script>
<style>

</style>